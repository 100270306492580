import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Nav from './Nav';

function OpeningHours() {
  return (
    <StaticQuery
      query={graphql`
        query {
          markdownRemark(fileAbsolutePath: { regex: "/opening-hours.md/" }) {
            html
          }
        }
      `}
      render={(data) => <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>}
    />
  );
}

export default function Header() {
  return (
    <header className="mb-5">
      <Nav />
      <div className="text-center py-4">
        <h1 className="text-blue text-3xl md:text-5xl font-bold mb-3">Vicarage Road Chippy</h1>
        <p className="text-blue md:text-lg">86 Vicarage Road, Morriston, Swansea</p>
      </div>
      <div className="bg-light-blue text-dark-blue text-center py-4">
        <p className="text-xl md:text-2xl font-bold mb-2">Opening Hours</p>
        <OpeningHours />
      </div>
    </header>
  );
}
