import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div>
      <Header />
      <main className="container mx-auto px-4">{children}</main>
      <Footer />
    </div>
  );
}
