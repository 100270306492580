import React from 'react';

export default function Footer() {
  return (
    <footer className="border-t border-blue text-blue text-right mt-5">
      <div className="container mx-auto py-3 px-4">
        <p>&copy; Vicarage Road Chippy</p>
      </div>
    </footer>
  );
}
