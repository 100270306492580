import React from 'react';
import Layout from '../layouts/Layout';

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="text-center py-10">
        <title>Not Found</title>
        <h2 className="text-2xl text-blue">Page not found!</h2>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
