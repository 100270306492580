import React from 'react';
import { Link } from 'gatsby';

const linkClasses = 'block px-4 py-4 border-b border-mid-blue hover:bg-mid-blue md:border-none';
const navClassess = 'absolute left-0 right-0 md:relative md:block md:container md:mx-auto md:flex md:justify-between bg-blue';

export default function Nav() {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-blue text-white">
      <div className={`px-4 py-4 text-right cursor-pointer border-b border-mid-blue md:hidden`} onClick={handleClick}>
        MENU
      </div>
      <div className={`${isOpen ? 'block' : 'hidden'} ${navClassess}`}>
        <div className="md:flex md:items-center">
          <Link className={linkClasses} activeClassName="bg-mid-blue" to="/">
            Home
          </Link>
          <Link className={linkClasses} activeClassName="bg-mid-blue" to="/our-menu">
            Our Menu
          </Link>
          <Link className={linkClasses} activeClassName="bg-mid-blue" to="/find-us">
            Find Us
          </Link>
        </div>
        <div className="md:flex md:items-center">
          <a className={linkClasses} href="https://www.facebook.com/groups/vicarageroadchippy/">
            Facebook
          </a>
        </div>
      </div>
    </nav>
  );
}
